import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {FaUserCircle} from 'react-icons/fa'
import {DropdownItem} from '~/bootstrap/components/dropdown'
import {NavItem, NavLink as BootstrapNavLink} from '~/bootstrap/components/nav'
import {NavbarBrand} from '~/bootstrap/components/navbar'
import {Link as BaseLink} from '~/common/components/link'
import {createUrl, getLinkText, linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoHeaderData} from './data'
import {DesktopHeader} from './desktop'
import {MobileHeader} from './mobile'
import {Customize} from '~/common/components/customize'

interface Props {
  data: KenticoHeaderData
}

/** Kentico type data. */
export {KenticoHeaderData}

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
`

const Link = styled(BaseLink)`
  &:not(:last-child) {
    display: block;
    border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  }
`

const NavLink = styled(BootstrapNavLink)`
  color: ${({theme}) => theme.colors.dark};
`

const DropdownLink = styled(DropdownItem)`
  && {
    padding: ${({theme}) => theme.spacers[3]};
  }

  &:hover {
    border-left: ${({theme}) => theme.spacers[2]} solid
      ${({theme}) => theme.colors.secondary};
  }

  &&:active {
    color: ${({theme}) => theme.colors.dark};
    background-color: ${({theme}) => theme.colors.light};
    outline: none;
  }
`

const FlexNavItem = styled(NavItem)`

  > a {
    color: ${({theme}) => theme.colors.dark}; 
  }
`

const UserIcon = styled(FaUserCircle)`
  margin-right: ${({theme}) => theme.spacers[2]};
  margin-bottom: ${({theme}) => theme.spacers[1]};
`

/**
 * Render header from Kentico data.
 * @return React component
 */
export const KenticoHeader = ({data}: Props) => {
  // Build logo and link
  let cashStoreLogo
  if (validImage(data.elements.logo)) {
    cashStoreLogo = <KenticoFixedImage image={data.elements.logo.value[0]} />
    if (linkedItems(data.elements.logoLink).length > 0) {
      cashStoreLogo = (
        <NavbarBrand
          aria-label={getLinkText(data.elements.logoLink)}
          tag={Link}
          to={createUrl(data.elements.logoLink)}
        >
          {cashStoreLogo}
        </NavbarBrand>
      )
    }
  }

  // Build mobile logo and link
  let mobileLogo
  if (validImage(data.elements.logoMobile)) {
    mobileLogo = (
      <KenticoFixedImage image={data.elements.logoMobile.value[0]} />
    )
    if (linkedItems(data.elements.logoLink).length > 0) {
      mobileLogo = (
        <NavbarBrand
          aria-label={getLinkText(data.elements.logoLink)}
          tag={Link}
          to={createUrl(data.elements.logoLink)}
        >
          {mobileLogo}
        </NavbarBrand>
      )
    }
  }

  // Build navigation links
  const linkElements = linkedItems(data.elements.links).map((link, index) => {
    try {
      return (
        // No better key
        // eslint-disable-next-line react/no-array-index-key
        <NavItem key={index}>
          <NavLink tag={Link} to={createUrl(link)}>
            {getLinkText(link)}
          </NavLink>
        </NavItem>
      )
    } catch {
      return undefined
    }
  })

  // Build dropdown nav links
  const dropdownLinks = linkedItems(data.elements.dropdownLinks)
  const dropdownElements = dropdownLinks.map((link, index) => {
    try {
      return (
        // No better key
        // eslint-disable-next-line react/no-array-index-key
        <Link key={index} to={createUrl(link)}>
          <DropdownLink>{getLinkText(link)}</DropdownLink>
        </Link>
      )
    } catch {
      return undefined
    }
  })

  const contactUsRoute = '/contact-us'
  const contactUsLink = <Link aria-current="page" to={contactUsRoute}>Contact Us</Link>
  // Build and display the Sign In Button depending on display radio button in Kentico
  const signInButtonDisplay = data.elements.signInDisplay.value[0].codename
  const signInButton =
    signInButtonDisplay === 'display' ? (
      <Customize component={FlexNavItem} textAlign="right">
        <Link to={data.elements.signInButtonUrl.value}>
          <UserIcon />
          {data.elements.signInButtonText.value}
        </Link>
      </Customize>
    ) : (
      <FlexNavItem/>
    )

  let editLink
  if (!UUID.is(data.system.name)) {
    editLink = <KenticoPreviewBodyLink contentItem={data.system.id} />
  }
  //Per user story 45308
  const pathsWithNOApplyNow = [
    "/application/prequal-cash-advance",
    "/pre-qualify-application",
    "/application/prequal-title-loan"]
  //Per user story 11009
  let shouldRenderButton = false

  if (typeof window !== 'undefined') {
    if(window.location.pathname.endsWith('approved')){
      shouldRenderButton = false
    }else if(!pathsWithNOApplyNow.includes(window.location.pathname)){
      shouldRenderButton = true
    }
  }

  if(shouldRenderButton){
    return (
      <Sticky>
        <DesktopHeader
          buttonLink={linkedItems(data.elements.buttonLink)}
          buttonText={data.elements.buttonText.value}
          dropdownLinks={dropdownElements}
          editLink={editLink}
          linkElements={linkElements}
          locatorLink={createUrl(data.elements.locatorLink)}
          logo={cashStoreLogo}
          phoneNumber={linkedItems(data.elements.phoneNumber)}
          shouldRenderButton={true}
          signInButton={signInButton}
          contactUs={contactUsLink}
        />
        <MobileHeader
          buttonLink={linkedItems(data.elements.buttonLink)}
          buttonText={data.elements.buttonText.value}
          links={linkedItems(data.elements.mobileLinkList)}
          locatorLink={createUrl(data.elements.locatorLink)}
          logo={mobileLogo}
          mobileButtonLink={linkedItems(data.elements.mobileButtonLink)}
          mobileButtonText={data.elements.mobileButtonText.value}
          phoneNumber={linkedItems(data.elements.phoneNumber)}
          shouldRenderButton={true}
          signInButtonDisplay={data.elements.signInDisplay.value[0].codename}
          signInButtonText={data.elements.signInButtonText.value}
          signInButtonUrl={data.elements.signInButtonUrl.value}
        />
      </Sticky>
    )
  }else{
    return (
      <Sticky>
        <DesktopHeader
          buttonLink={linkedItems(data.elements.buttonLink)}
          buttonText={data.elements.buttonText.value}
          dropdownLinks={dropdownElements}
          editLink={editLink}
          linkElements={linkElements}
          locatorLink={createUrl(data.elements.locatorLink)}
          logo={cashStoreLogo}
          phoneNumber={linkedItems(data.elements.phoneNumber)}
          shouldRenderButton={false}
          signInButton={signInButton}
          contactUs={contactUsLink}
        />
        <MobileHeader
          buttonLink={linkedItems(data.elements.buttonLink)}
          buttonText={data.elements.buttonText.value}
          links={linkedItems(data.elements.mobileLinkList)}
          locatorLink={createUrl(data.elements.locatorLink)}
          logo={mobileLogo}
          mobileButtonLink={linkedItems(data.elements.mobileButtonLink)}
          mobileButtonText={data.elements.mobileButtonText.value}
          phoneNumber={linkedItems(data.elements.phoneNumber)}
          shouldRenderButton={false}
          signInButtonDisplay={data.elements.signInDisplay.value[0].codename}
          signInButtonText={data.elements.signInButtonText.value}
          signInButtonUrl={data.elements.signInButtonUrl.value}
        />
      </Sticky>
    )
  }
}
