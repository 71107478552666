import React, {useContext} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {DayOptions} from '~/common/components/day-options'
import {MonthOptions} from '~/common/components/month-options'
import {Noop} from '~/common/components/noop'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {YearOptions} from '~/common/components/year-options'
import {MASKS, PATTERNS} from '~/common/util'
import {GooglePlaces} from '~/google/components/places'
import {Recaptcha} from '~/google/components/recaptcha'
import {WebFormContext} from '../../context'
import {useOnGeocode} from '../form/hooks'
import {FormData, useOnChange, useOnSubmit} from './hooks'

type WebFormProps = import('../form').WebFormProps

interface Props extends WebFormProps {
  type: 'CASH_ADVANCE' | 'INSTALLMENT'
}

const DateOfBirthHeader = styled.h4`
  padding-top: ${({theme}) => theme.spacers[5]};
`

const Container = styled(BootstrapContainer)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
      padding: 0 ${({theme}) => theme.spacers[3]};
    }
  }
`

const ShowMeBtn = styled(Button)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const StyledForm = styled(FormGroup)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      text-align: center;
      padding-right: 1rem;
    }
  }
`

const Disclaimer = styled.small`
  > p {
    margin: 0;
  }
`

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

/**
 * Render prequal installment form.
 * @return React component
 */
 export const NonTitleForm = ({
  submissionLinks,
  privacyConsent,
  tcpa,
  type,
}: Props) => {
  const {cashAdvance, installment} = useContext(WebFormContext)
  const api = type === 'INSTALLMENT' ? installment : cashAdvance
  const form = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  })
  const {
    handleSubmit,
    formState: {isValid},
  } = form
  const onChange = useOnChange(form)
  const onSubmit = useOnSubmit({api, submissionLinks})
  const onInput = useOnGeocode(form)
  const isAddressOneLoaded = form.getValues().addressOne === undefined ? false : true
  const isTCPARequired = process.env.TCPA_CHECKBOX_REQUIRED?.toLowerCase() === 'true'

  const localRecaptcha = (process.env.GOOGLE_RECAPTCHA_DISABLE_FLAG != 'true') ?
   <Recaptcha required name="recaptcha" />
   : <Noop/>
  return (
    <FormContext {...form}>
      <Container>
        <Form
          id="nonTitleForm"
          onInput={onInput}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup>
            <FormText color="secondary">*Required Fields</FormText>
          </FormGroup>
          <Row>
            <Col sm>
              <FormItem
                label="First Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="firstName"
                required="Please enter your first name"
                type="text"
              />
            </Col>
            <Col sm>
              <FormItem
                label="Last Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="lastName"
                required="Please enter your last name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                protect
                label="Email Address"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="email"
                pattern={PATTERNS.EMAIL}
                required="Please enter your email address"
                type="email"
              />
            </Col>
          </Row>
          <DateOfBirthHeader>Date of Birth</DateOfBirthHeader>
          <Row>
            <Col>
              <Row>
                <Col md="4" sm="12">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Month"
                    name="dateOfBirth.month"
                    type="select"
                  >
                    <option disabled value="">
                      Month
                    </option>
                    <MonthOptions />
                  </FormItem>
                </Col>
                <Col md="4" sm="12">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Day"
                    name="dateOfBirth.day"
                    type="select"
                  >
                    <option disabled value="">
                      Day
                    </option>
                    <DayOptions />
                  </FormItem>
                </Col>
                <Col md="4" sm="12">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Year"
                    name="dateOfBirth.year"
                    type="select"
                  >
                    <option disabled value="">
                      Year
                    </option>
                    <YearOptions />
                  </FormItem>
                </Col>
                <Col sm>
                  <FormItem
                    protect
                    label="Phone"
                    mask={MASKS.PHONE}
                    name="phone"
                    pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
                    required="Please enter your phone number"
                    type="tel"
                  />
                </Col>
                <Col sm>
                  <FormItem
                    protect
                    required
                    label="Last 4 of Social"
                    mask={MASKS.SSN}
                    name="socialNumber"
                    pattern={/^[0-9]{4}$/}
                    type="social"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <GooglePlaces
                label="Address 1"
                name="addressOne"
                placeholder=""
                required="Please enter an address"
                pattern={/^[^\s].+[^\s]$/}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                label="Address 2"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 150)}
                name="addressTwo"
                placeholder="APT, Suite, Unit, etc."
                pattern={/^[^\s].+[^\s]$/}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm>
              <FormItem
                label="City"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="city"
                required="Please enter the name of the city you live in"
                pattern={/^[^\s].+[^\s]$/}
                type="text"
              />
            </Col>
            <Col sm>
              <FormItem
                defaultValue=""
                label="State"
                name="state"
                required="Please select the state you live in"
                type="select"
              >
                <option disabled value="">
                  Select a State
                </option>
                <StateOptions all />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <FormItem
                label="Zip Code"
                mask={MASKS.ZIP_CODE}
                name="zipCode"
                pattern={/^[0-9]{5}/}
                required="Please enter your zip code"
                type="tel"
              />
            </Col>
          </Row>
          <FormItem
            label={<Disclaimer>{privacyConsent}</Disclaimer>}
            name="privacyConsent"
            required="You must agree to these terms and conditions"
            type="checkbox"
          />
          <FormItem
            label={<Disclaimer>{tcpa}</Disclaimer>}
            name="tcpa"
            required={isTCPARequired ? "You must agree to these terms and conditions" : false}
            type="checkbox"
          />
          {localRecaptcha}
          <StyledForm>
            <ShowMeBtn color="tertiary" disabled={!isValid || !api.ready || !isAddressOneLoaded}>
              {api.ready ? 'Show Me How Much' : <Spinner />}
            </ShowMeBtn>
          </StyledForm>
        </Form>
      </Container>
    </FormContext>
  )
}
