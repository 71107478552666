/* eslint-disable sonarjs/cognitive-complexity */
import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {MASKS, PATTERNS} from '~/common/util'
import {ContactContext} from '~/contact/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoContactUsFormData} from './data'
import {FormData, createOnSubmit} from './form'
import Axios from 'axios'
import {flatten as flatUtil} from 'flat'

interface Props {
  data: KenticoContactUsFormData
  /** Reserved for testing. This should not be used normally. */
  success?: boolean
  onChangeHandler?: () => void
}

interface FeedbackSelections {
  customerType: string | null
  natureOfReport: string | null
}

/** Kentico type data. */
export {KenticoContactUsFormData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[4]};
`

const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.red};
  font-weight:600;
  margin-bottom: 15px;
  display: inline-block;
`

const SuccessMessage = styled.span`
  color: ${({theme}) => theme.colors.secondary};
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-top: ${({theme}) => theme.spacers[5]};
  }
`

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

/**
 * Render contact us form.
 * @return React component
 */
export const KenticoContactUsForm = ({
  data: {
    elements: {header},
    system: {id, name},
  },
  success: defaultSuccess,
}: Props) => {

  const contextineURL = process.env.FEEDBACK_COMPLAINT_URL
  if (!contextineURL) {
    throw new Error('contextine URL is not defined')
  }

  const [showLoanAndApplicationForm, setShowLoanAndApplicationForm] = useState(false)
  const [showFeedbackAndComplaintsForm, setShowFeedbackAndComplaintsForm] = useState(false)
  const [isSubmitFeedbackEnabled, setIsSubmitFeedbackEnabled] = useState(false)
  const [feedbackSelections, setFeedbackSelections] = useState<FeedbackSelections>({
    customerType: null,
    natureOfReport: null,
  })
  const [feedbackComplaintFields, setFeedbackComplaintFields] = useState({
    customerFirstName: "",
    customerLastName: "",
    customerEMail: "",
    customerPhoneNumber: "",
    customerAddress: "",
    customerCity: "",
    customerState: "",
    customerZip: "",
    responseDiscoverIssueDate: "",
    responseNatureOfFeedbackToReport: "",
  })

  const [isFeedbackFormLoading, setIsFeedbackFormLoading] = useState(false)
  const [feedbackResponse, setFeedbackResponse] = useState<{displayName: string, id: string} | null>(null)
  const [showFeedbackOnError, setShowFeedbackOnError] = useState(false)
  const [activeButton, setActiveButton] = useState<string | null>(null)

  const scrollToResponse = () => {
    setTimeout(() => {
      window.scrollTo({
        top: window.innerHeight / 2,
        behavior: "smooth",
      })
    }, 0)
  }

  const addAppInsightEvent = (eventName: string, payload: any) => {
    const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent({name: eventName, properties: flatUtil.flatten(payload)})
  }
  }

  const handleFeedbackAndComplaintsFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsFeedbackFormLoading(true)

    try {
      const payload = {
        ...feedbackComplaintFields, 
        ...feedbackSelections, 
        brand: "Cash Store", 
        sendEmail: true, 
        template: "feedbackcomplaint",
        customerAddress2: "",
        customerLoanNumber: "",
        customerSSN: "",
        customerFeedbackDescription: feedbackComplaintFields.responseNatureOfFeedbackToReport,
        responseABANumber: "",
        responseBankAccountNumber: "",
        responseCardExpiryDate: "",
        responseCardFirst6: "",
        responseCardLast4: "",
        responseDisputedPaymentAmount: "",
        responseDisputedPaymentDate: "",
        responseIssueDescription: "",
        responseLawEnAgencyCaseNumber: "",
        responseLawEnAgencyDetails: "",
        responseSuspectDetails: "",
      }

      const response = await Axios.post(
        contextineURL,
        payload,
        {
          headers:{
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwi'+
            'bmFtZSI6IkNOR0ZlZWRiYWNrIiwiaWF0IjoxNTE2MjM5MDIyfQ.FMAXeNirpPIN7qMqqt95DC3Zo1mNrZd-Es9Z-DqNnw4',
            'Content-Type' : 'application/json',
          }
        }
      )

      if (response.data) {
        setFeedbackResponse(response.data)
        setShowFeedbackAndComplaintsForm(false)
        setIsSubmitFeedbackEnabled(false)
        addAppInsightEvent('Cashstore:ContactUsFeedbackComplaintForm', payload)
        scrollToResponse()
      }
    } catch (error: any) {
      setShowFeedbackOnError(true)
      setShowFeedbackAndComplaintsForm(false)
      setFeedbackResponse(null)
      addAppInsightEvent('Cashstore:ContactUsFeedbackComplaintFormError', error)
      scrollToResponse()
    } finally {
      setIsFeedbackFormLoading(false)
    }    
  }

  const resetFeedbackResponse = () => {
    setFeedbackResponse(null);
    setShowFeedbackOnError(false);
  }

  const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, '')
    if (digits.length === 0) {
      return ``
    } else if (digits.length ===1) {
      return `(${digits}`
    } else if (digits.length <= 3) {
      return `(${digits}`
    } else if (digits.length <= 6) {
      return `(${digits.slice(0,3)}) ${digits.slice(3)}`
    }
    return `(${digits.slice(0,3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
  }

  function handleDropDownChange(){
    const e : Event | undefined = event
    const trgt: HTMLSelectElement  = e?.target as HTMLSelectElement
    const {value} = trgt

    setFeedbackSelections((prev: FeedbackSelections) => ({
      ...prev,
      natureOfReport: value
    }))
  }


  function handleChange() {
    const e : Event | undefined = event
    const trgt: HTMLInputElement  = e?.target as HTMLInputElement
    const {name, value} = trgt

    if (name === 'customerPhoneNumber') {
      const formattedPhone = formatPhoneNumber(value)
      trgt.value = formattedPhone  

      setFeedbackComplaintFields((prev) => ({
        ...prev,
        customerPhoneNumber: formattedPhone,
      }))
    } else {
    setFeedbackComplaintFields((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

    validateFeedbackForm({...feedbackComplaintFields, [name]: value})
  }

  const validateFeedbackForm = (onChangeValues: typeof feedbackComplaintFields) => {
    const isFeedbackFormValid = feedbackSelections.customerType !== null && feedbackSelections.natureOfReport !== null
    && Object.values(onChangeValues).every((field) => field.trim() !== "" && field !== null && field !== undefined)

    setIsSubmitFeedbackEnabled(isFeedbackFormValid)
  }



  const contact = useContext(ContactContext)
  const form = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const feedbackForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    formState: {isValid},
    reset
  } = form
  const [success, setSuccess] = useState<boolean | undefined>(defaultSuccess)
  const onSubmit = useCallback(
    createOnSubmit({
      contact,
      setSuccess,
      reset
    }),
    [contact, setSuccess],
  )

  let response
  switch (success) {
    case true:
      response = (
        <SuccessMessage>
          Your question or comment has been submitted! A Cash Store representative will respond
           to your inquiry as soon as possible.
        </SuccessMessage>
      )
      break
    case false:
      response = (
        <ErrorMessage>
          We are unable to submit your information at this time. Please try
          again later.
        </ErrorMessage>
      )
      break
    default:
      break
  }

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <KenticoRichText data={header} />
      <Row>
      <Col className="col-lg-3 col-12 order-lg-1">
        <Row className="mb-3">
          <p>
            <strong>General Inquiries</strong><br />
            2100 West Walnut Hill Lane<br />
            Suite 300<br />
            Irving, TX 75038
          </p>
        </Row>
        <Row className="mb-3">
          <p>
            <strong>Complaints</strong><br />
            Cash Store<br />
            P.O. Box 36454<br />
            Cincinnati, OH 45236-0454
          </p>
        </Row>
        <Row className="mb-3">
          <p>
            <strong>Feedback Hotline</strong><br />
            844-462-8712
          </p>
        </Row>
      </Col>

      <Col className="col-lg col-12 order-lg-2">
        <Row className="mb-4">
          <p>
            <strong>Questions or Concerns?</strong><br />
            For any questions, please feel free to contact one of our Cash Store Locations or give
            us a call. You can also contact our Customer Service team by choosing one of the
            options below.
          </p>
        </Row>
        <Row className="mb-4">
          <ButtonContainer>
          <Button style={{ padding: '8px 16px', width: '260px', fontSize: '1rem',
            backgroundColor: activeButton === 'LAQ' ? "#5558af" : "#d3d3d3",
            borderColor: activeButton === 'LAQ' ? "#5558af" : "#d3d3d3",
            color: activeButton === 'LAQ' ? "white" : "black"
          }}
            variant="secondary"
            onClick={() => {setActiveButton("LAQ");
              setShowLoanAndApplicationForm(true);
              setShowFeedbackAndComplaintsForm(false);
              resetFeedbackResponse();
              }
            }
          >
            <div style={{ display: 'flex', gap: '10px' }}>
            <span style={{ marginRight: '5px' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80 80"
                width="50"
                height="50"
                fill={ activeButton === 'LAQ' ? "white" : "black" }
              >
              <path className="st0"
                d={`M40.01,1.19c-21.22,0-38.49,17.27-38.49,38.49c0,7.98,2.42,15.63,7,22.13c0.63,0.9,
                  1.87,1.12,2.77,0.48 c0.9-0.63,1.11-1.88,0.48-2.77C7.67,53.69,5.5,46.83,5.5,39.68c0-19.03,
                  15.48-34.51,34.51-34.51s34.51,15.48,34.51,34.51 S59.04,74.19,40.01,74.19c-7.16,
                  0-14.02-2.17-19.85-6.27c-0.9-0.64-2.15-0.41-2.77,0.48c-0.64,0.9-0.42,2.14,0.48,2.77 c6.5,
                  4.58,14.16,7,22.14,7c21.22,0,38.49-17.27,38.49-38.49S61.23,1.19,40.01,1.19z`}
              ></path>
              <circle className="st0" cx="40.17" cy="59.55" r="2.84"></circle>
              <path className="st0"
                d={`M40.17,17.87c-7.53,0-13.64,6.12-13.64,13.65c0,1.26,1.02,2.27,2.27,2.27c1.25,0,2.27-1.02,
                  2.27-2.27 c0-5.02,4.08-9.1,9.1-9.1c5.02,0,9.1,4.08,9.1,9.1c0,3.23-1.77,4.83-4.64,7.19c-3,
                  2.45-6.73,5.51-6.73,11.76v3.57h4.53v-3.3 c0.01-0.09,0.02-0.18,0.02-0.27c0-4.1,2.24-5.93,
                  5.06-8.24c2.96-2.42,6.31-5.16,6.31-10.71C53.82,23.99,47.7,17.87,40.17,17.87z`}
              ></path>
              </svg>
            </span>
            <div style={{ textAlign: 'left' }}>
            Loan & Application
            Questions
            </div>
            </div>
          </Button>              
          <Button style={{ padding: '8px 16px', width: '260px', fontSize: '1rem',
            backgroundColor: activeButton === 'FAC' ? "#5558af" : "#d3d3d3",
            borderColor: activeButton === 'FAC' ? "#5558af" : "#d3d3d3",
            color: activeButton === 'FAC' ? "white" : "black"
           }}
          variant="secondary"
          onClick={() => {setActiveButton("FAC");
            setShowLoanAndApplicationForm(false);
            setFeedbackSelections({customerType: null, natureOfReport: null})
            setShowFeedbackAndComplaintsForm(true);
            resetFeedbackResponse();
          }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
            <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80 80"
              width="50"
              height="50"
              fill={ activeButton === 'FAC' ? "white" : "black" }
            >
              <path className="st0"
              d={`M40.2,1.74c-21.22,0-38.49,17.27-38.49,38.49c0,7.98,2.42,15.63,7,22.13c0.63,0.9,1.87,1.12,
                2.77,0.48 c0.9-0.63,1.11-1.88,0.48-2.77c-4.1-5.83-6.27-12.69-6.27-19.84C5.69,21.2,21.17,5.72,
                40.2,5.72S74.71,21.2,74.71,40.23 S59.22,74.74,40.2,74.74c-7.16,
                0-14.02-2.17-19.85-6.27c-0.9-0.64-2.15-0.41-2.77,
                0.48c-0.64,0.9-0.42,2.14,0.48,2.77 c6.5,4.58,14.16,7,22.14,7c21.22,0,38.49-17.27,38.49-38.49S61.42,
                1.74,40.2,1.74z`}
              ></path>
              <path className="st0"
              d={`M61.52,21.17H19.17c-1.45,0-2.63,1.18-2.63,2.63v29.27c0,1.45,1.18,2.63,2.63,2.63h19.35l13.55,
                10.05 c0.45,0.28,0.94,0.42,1.44,0.42c0.45,0,0.9-0.12,1.32-0.34c0.91-0.51,
                1.48-1.49,1.48-2.57V55.7h5.22c1.45,
                0,2.63-1.18,2.63-2.63 V23.8C64.15,22.35,62.97,21.17,61.52,21.17z M60.7,52.21h-7.56c0,0,0.02,1.05,0.02,
                1.92l0.13,8.62l-13.32-9.89l-0.94-0.66H19.98 v-27.6H60.7V52.21z`}
              ></path>
              <rect x="27" y="32.62" className="st0" width="26.69" height="3.41"></rect>
              <rect x="27" y="41.83" className="st0" width="26.69" height="3.41"></rect>
            </svg>
            </span>
            <div style={{ textAlign: 'left' }}>
            Feedback 
            & Complaints
            </div>
            </div>
          </Button>
          </ButtonContainer>
        </Row>

          {showLoanAndApplicationForm && (
            <Row>
            <FormContext {...form}>
              <Form id="contactUsForm" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <FormText color="secondary">*Required Fields</FormText>
                </FormGroup>
                <Row>
                  <Col>
                    <FormItem
                      defaultValue=""
                      label="Please select the nature of your inquiry"
                      name="natureOfInquiry"
                      type="select"
                      required="Please select the nature of your inquiry"
                    >
                      <option value="" disabled hidden>Please select...</option>
                      <option value="Status of Application">Status of Application</option>
                      <option value="Status of Open Loan">Status of Open Loan</option>
                      <option value="Refinance and Rollover Options">Refinance and Rollover Options</option>
                      <option value="Payment Inquiry">Payment Inquiry</option>
                      <option value="Update Personal Information">Update Personal Information</option>
                      <option value="Login or Password Inquiry">Login or Password Inquiry</option>
                      <option value="Past Due Account">Past Due Account</option>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg>
                    <FormItem
                      label="First Name"
                      maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                      name="firstName"
                      required="Please enter a first name"
                      type="text"
                    />
                  </Col>
                  <Col lg>
                    <FormItem
                      label="Last Name"
                      maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                      name="lastName"
                      required="Please enter a last name"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg>
                    <FormItem
                      label="Email Address"
                      maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                      name="email"
                      pattern={PATTERNS.EMAIL}
                      required="Please enter an email"
                      type="email"
                    />
                  </Col>
                  <Col lg>
                    <FormItem
                      label="Phone"
                      mask={MASKS.PHONE}
                      name="phone"
                      pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
                      required="Please enter a phone number"
                      type="tel"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg>
                    <FormItem
                      defaultValue=""
                      label="Please provide any additional information that will assist us with your inquiry."
                      name="comments"
                      type="textarea"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p style={{color: 'red'}}>
                        ALERT: To protect your privacy, we recommend you do not include personally identifiable 
                        information (PII), such as Social Security Number, Date of Birth, Bank Account number, 
                        Driver&apos;s License number, etc. in your statement.
                    </p>
                  </Col>
                </Row>
                {response}
                <FormGroup>
                  <Button color="tertiary" disabled={!contact.ready || !isValid}>
                    {contact.ready ? 'Submit' : <Spinner />}
                  </Button>
                </FormGroup>
              </Form>
            </FormContext>
            </Row>
          )}

            {showFeedbackAndComplaintsForm && (
              <FormContext {...feedbackForm}>
              <>
                <Row><FormGroup><FormText color="secondary">*Required Fields</FormText></FormGroup></Row>
                <Row>
                      <div className="form-check">
                      <Row><label style={{ fontSize: '80%'}}>I am a customer of Cash Store*</label></Row>
                        <Row>
                          <label style={{ fontSize: '80%'}}>
                            <input
                              type="radio"
                              name="customerType"
                              value="Customer"
                              onChange={() => setFeedbackSelections((prev) => ({...prev, customerType: "Customer"}))}
                            /> Yes
                          </label>
                        </Row>
                      
                        <Row>
                          <label style={{ fontSize: '80%'}}>
                            <input
                              type="radio"
                              name="customerType"
                              value="Non-Customer"
                              onChange={() => setFeedbackSelections((prev) => ({
                                ...prev, customerType: "Non-Customer"}))}
                            /> No
                          </label>
                        </Row>             
                      </div>
                      
                      <Row>
                        <Col>
                          <FormItem 
                            defaultValue=""
                            name="natureOfReport"
                            type="select"
                            label="Please select the nature of the report"
                            required="Please select an option"
                            onChange={handleDropDownChange}
                          >
                            <option value="" disabled hidden>Please select...</option>
                            <option value="responsePleasedWithStaff">I was pleased with the
                            professional, knowledgeable staff and the quick handling of my account.</option>
                            <option value="responseUpdateCommunication">I would like to update my
                            communication preferences.</option>
                            <option value="responseDissatisfiedWithService">I’m dissatisfied with
                            the level of Customer Service I received.</option>
                            <option value="responsePaymentConcern">I have a concern regarding my payment.</option>
                            <option value="responseDeniedLoan">I was denied for a loan and
                            do not understand why or what to do next.</option>
                            <option value="responseConcernMyAccount">I have a concern regarding my
                            account or loan.</option>
                            <option value="responseConcernMyCreditReport">I have a concern
                            regarding my credit report.</option>
                          </FormItem>
                        </Col>
                      </Row>

                      {feedbackSelections.customerType && feedbackSelections.natureOfReport && (
                      
                        <Form id="feedbackFrom" onSubmit={handleFeedbackAndComplaintsFormSubmit}>
                          <>
                        <strong>Personal Information</strong>
                          <Row>
                            <Col lg>
                              <FormItem
                                label="First Name"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                                name="customerFirstName"
                                required="Please enter a first name"
                                type="text" 
                                onChange={handleChange}
                                />
                            </Col>
                            <Col lg>
                              <FormItem
                                label="Last Name"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                                name="customerLastName"
                                required="Please enter a last name"
                                type="text" 
                                onChange={handleChange}
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg>
                              <FormItem
                                label="Email Address"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                                name="customerEMail"
                                pattern={PATTERNS.EMAIL}
                                required="Please enter an email"
                                type="email" 
                                onChange={handleChange}
                                />
                            </Col>
                            <Col lg>
                              <FormItem
                                label="Phone"
                                name="customerPhoneNumber"
                                pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
                                maxLength={14}
                                required="Please enter your phone number"
                                type="tel"
                                onChange={handleChange} 
                              /> 
                            </Col>
                          </Row>
                          <Row>
                            <Col lg>
                              <FormItem
                                label="Address"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                                name="customerAddress"
                                required="Please enter an address"
                                type="text" 
                                onChange={handleChange}
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-md-6">
                              <FormItem
                                label="City"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 150)}
                                name="customerCity"
                                required="Please enter a city"
                                type="text" 
                                onChange={handleChange}
                                />
                            </Col>
                            <Col className="col-12 col-md-4">
                              <FormItem
                                label="State"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 2)}
                                name="customerState"
                                required="Please enter a state"
                                type="select" 
                                onChange={handleChange}
                              >
                                <option value="" />
                                  <StateOptions all />
                              </FormItem>
                            </Col>
                            <Col className="col-12 col-md-2">
                              <FormItem
                                label="Zip"
                                name="customerZip"
                                pattern={/^[0-9]{5}/}
                                required="Please enter your zip code"
                                maxLength={5}
                                type="text"
                                onChange={handleChange}
                                />
                            </Col>
                          </Row>
                          <strong>Feedback Information</strong>
                          <Row style={{width: '300px'}}>
                            <Col>
                              <FormItem 
                                label="When did you discover this concern?"
                                maxLength={errorValue(MAX_LENGTH_MESSAGE, 150)}
                                name="responseDiscoverIssueDate"
                                required="Please enter a date"
                                type="date" 
                                onChange={handleChange}
                                />
                            </Col>
                          </Row>
                      <Row>
                        <Col lg>
                          <FormItem
                            defaultValue=""
                            label="Explain the nature of the feedback/complaint"
                            name="responseNatureOfFeedbackToReport"
                            type="textarea"
                            required="Please enter some feedback"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{color: 'red'}}>
                              ALERT: To protect your privacy, we recommend you do not include personally identifiable 
                              information (PII), such as Social Security Number, Date of Birth, Bank Account number, 
                              Driver&apos;s License number, etc. in your statement.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button color="tertiary" disabled={!isSubmitFeedbackEnabled || isFeedbackFormLoading}>
                            {!isFeedbackFormLoading ? 'Submit' : <Spinner />}
                          </Button>
                        </Col>
                      </Row>
                      </>
                        </Form>                     
                      )}
                </Row>
              </>
            </FormContext>
            )}

            {feedbackResponse?.displayName && (
              <Row>
                <Col>
                  <SuccessMessage>
                    <p>
                        Your request has been successfully submitted.<br />Your Case Number is: 
                        {feedbackResponse?.displayName}.<br /><br />Customer satisfaction is most important to us. 
                        We’ve provided a case number for you to check on the
                        status of your case or to provide additional information, if necessary.
                        <br /><br />Please note cases are worked in the order they are received. The following are 
                        general timelines; responses can vary depending on seasonality and submission volume.
                        <br /><br />Feedback & Complaints: 15 days
                        <br /><br />Please contact us via our toll-free number to check on the status of your request: 
                        844-462-8712.
                        <br /><br />Thank you.
                    </p>
                  </SuccessMessage>
                </Col>
              </Row>
            )}

            {showFeedbackOnError && (
              <Row>
                <Col>
                  <p style={{color: 'red'}}>
                    Failed to submit the feedback/complaint. Please try again after some time.
                  </p>
                </Col>
              </Row>
            )}
      </Col>
      </Row>
    </Container>
  )
}
