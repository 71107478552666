import {ContactContext} from '~/contact/context'
import {ContactForm} from '~/contact/types'
import {flatten as flatUtil} from 'flat'
import {DeepPartial} from 'react-hook-form'


/** Form data structure. */
export interface FormData {
  firstName: string
  lastName: string
  email: string
  phone: string
  comments?: string
  natureOfInquiry: string
}

interface Options {
  reset: (values?: DeepPartial<FormData> | undefined) => void
  contact: ContactContext
  setSuccess(value: boolean): void;

}

/**
 * Create on submit handler.
 * @return Submit handler
 */
export const createOnSubmit = ({contact, setSuccess, reset}: Options) => async (
  formData: FormData,
) => {
  let data: ContactForm = {
    comments: formData.comments,
    email: formData.email,
    name: {
      first: formData.firstName,
      last: formData.lastName,
    },
    phone: formData.phone,
    natureOfInquiry: formData.natureOfInquiry,
  }

  const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent('Cashstore:ContactUsForm', flatUtil.flatten(data))
  }

  const result = await contact.submit(data)
  if(result === true){
    reset()
  }

  setSuccess(result)
}
